import React from 'react';
import Svg from '../images/not-found.svg';

const svgStyle = {
  width: '70%',
  height: 'auto'
};

const divStyle = {
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export default () => {
  return (
    <div style={divStyle}>
      <Svg style={svgStyle} />
    </div>
  );
};
